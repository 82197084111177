import React from 'react';
import { Link } from "gatsby";

import MapImg from "../../assets/images/map.png";

const FunFactsArea = () => {
    return (
        <div className="funfacts-area ptb-80">
            <div className="container">
                <div className="section-title">
                    <h2>Unsere Leidenschaft in Zahlen.</h2>
                    <div className="bar"></div>
                    <p>Fakten sprechen für sich.</p>
                </div>

                <div className="row">
                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>&gt;3 Mio.</h3>
                            <p>Druck-PDFs erstellt</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>&gt;5000</h3>
                            <p>Layouts täglich</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>&gt;2 Mio.</h3>
                            <p>Assets</p>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-3 col-6">
                        <div className="funfact">
                            <h3>&gt;13000</h3>
                            <p>Workflows</p>
                        </div>
                    </div>
                </div>

                {/*<div className="contact-cta-box">
                    <h3>Have Any Questions About Us?</h3>
                    <p>Don't hesitate to contact us</p>

                    <Link to="/contact" className="btn btn-primary">
                        Contact Us
                    </Link>
                </div>*/}

                <div className="map-bg">
                    <img src={MapImg} alt="map" />
                </div>
            </div>
        </div>
    )
}

export default FunFactsArea;